import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "./Layout"

const getNextPrev = (items, item) => {
  const ix = items.findIndex(({ src }) => src === item.src)

  return {
    prev: ix > 0 ? items[ix - 1] : null,
    next: ix < items.length - 1 ? items[ix + 1] : null,
  }
}

const Modal = ({ items, item, setItem }) => {
  const { prev, next } = getNextPrev(items, item)

  // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  useEffect(() => {
    const body = document.body
    const scrollY = window.scrollY

    body.style.top = `-${scrollY}px`
    body.style.position = "fixed"

    return () => {
      body.style.position = ""
      body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0"))
    }
  }, [])

  useEffect(() => {
    const keyPressHandler = e => {
      if (e.defaultPrevented) {
        return
      }

      let handled = false

      switch (e.key) {
        case "Left": // IE/Edge specific value
        case "ArrowLeft":
          handled = true
          if (prev) setItem(prev)
          break
        case "Right": // IE/Edge specific value
        case "ArrowRight":
          handled = true
          if (next) setItem(next)
          break
        case "Escape":
          setItem(null)
          break
        default:
          break
      }

      if (handled) {
        e.preventDefault()
      }
    }

    window.addEventListener("keydown", keyPressHandler)

    return () => {
      window.removeEventListener("keydown", keyPressHandler)
    }
  })

  return <Layout {...{ next, prev, item, setItem }} />
}

export default Modal
