import React from "react"
import styled from "styled-components"
import iglogoSrc from "./iglogo.png"

const NavBarWrapper = styled.nav`
  width: 100%;
  height: 54px;
  border-bottom: 1px solid rgb(219,219,219);
`

const NavBar = () => {
  return <NavBarWrapper></NavBarWrapper>
}

export default NavBar
