import React from "react"
import styled from "styled-components"

const CloseLink = ({ to }) => (
  <svg ariaLabel="Close" fill="#fff" height="24" viewBox="0 0 48 48" width="24">
    <path
      clipRule="evenodd"
      d="M41.8 9.8L27.5 24l14.2 14.2c.6.6.6 1.5 0 2.1l-1.4 1.4c-.6.6-1.5.6-2.1 0L24 27.5 9.8 41.8c-.6.6-1.5.6-2.1 0l-1.4-1.4c-.6-.6-.6-1.5 0-2.1L20.5 24 6.2 9.8c-.6-.6-.6-1.5 0-2.1l1.4-1.4c.6-.6 1.5-.6 2.1 0L24 20.5 38.3 6.2c.6-.6 1.5-.6 2.1 0l1.4 1.4c.6.6.6 1.6 0 2.2z"
      fillRule="evenodd"
    />
  </svg>
)

const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  cursor: pointer;
`

export const CloseBtn = ({ to }) => (
  <CloseBtnWrapper>
    <CloseLink {...{ to }} />
  </CloseBtnWrapper>
)

const SvgRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="40">
    <defs>
      <clipPath>
        <path fill="#00f" fillOpacity=".514" d="m-7 1024.36h34v34h-34z" />
      </clipPath>
      <clipPath>
        <path fill="#aade87" fillOpacity=".472" d="m-6 1028.36h32v32h-32z" />
      </clipPath>
    </defs>
    <path
      d="m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
      transform="matrix(.03541-.00013.00013.03541 2.98 3.02)"
      fill="rgba(255, 255, 255, .75)"
    />
  </svg>
)

const NextBtnWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -40px;
  cursor: pointer;
  transform: translate(0, -50%);
`

const PrevBtnWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -40px;
  cursor: pointer;
  transform: translate(0, -50%) rotate(180deg);
`

export const NextBtn = ({ setItem, item}) => (
  <NextBtnWrapper onClick={() => setItem(item)}>
    <SvgRight />
  </NextBtnWrapper>
)

export const PrevBtn = ({ setItem, item}) => (
  <PrevBtnWrapper onClick={() => setItem(item)}>
    <SvgRight />
  </PrevBtnWrapper>
)