import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Instagram from "../components/instagram"
import media from "../instagram-content/media.json"

const { photos, videos } = media

const byDate = (a, b) => new Date(b.taken_at) - new Date(a.taken_at)

const rawItems = [
  ...photos,
  ...videos.map(o => ({
    ...o,
    isVideo: true,
  })),
].reduce((acc, item) => {
  acc[item.path] = item
  return acc
}, {})

const InstaPage = ({ data }) => {
  const {
    allFile: { nodes },
  } = data

  const items = nodes
    .map(({ publicURL: src, relativePath, childImageSharp }) => {
      const path = relativePath.replace("ig/", "")
      const item = rawItems[path]

      return {
        ...item,
        src,
        gatsbyImageData: childImageSharp && childImageSharp.gatsbyImageData ? childImageSharp.gatsbyImageData : null
      }
    })
    .sort(byDate)

  return (
    <>
      <Helmet>
        <title>VS Instagram Backup</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Instagram {...{ items }} />
    </>
  )
}

export default InstaPage

export const pageQuery = graphql`
  query Insta {
    allFile(filter: { relativePath: { regex: "/ig//" } }) {
      nodes {
        publicURL
        relativePath
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  }
`
