import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { CloseBtn, NextBtn, PrevBtn } from "./Icons"
import RightBox from "./RightBox"

const Wrapper = styled.div`
  position: fixed;
  z-index: 40;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 41;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  @media (min-width: 481px) {
    padding: 0 40px;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 935px;
  position: relative;
  background: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  display: flex;
`

const ItemWrap = styled.div`
  margin-right: 335px;
  background: rgba(0, 0, 0, 1);

  @media (min-width: 1016px) {
    min-height: 600px;
  }

  & > video,
  & > img {
    display: block;
  }
`

export default ({ next, prev, item, setItem }) => {
  const { caption, src, isVideo } = item

  const vidRef = useRef(null)
  const previousSrc = useRef(src)

  useEffect(() => {
    if (previousSrc.current !== src && vidRef.current) {
      vidRef.current.load()
      previousSrc.current = src
    }

    if (isVideo) {
      vidRef.current.play()
    }
  }, [src])

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <ItemWrap>
            {isVideo ? (
              <video ref={vidRef} controls={false}>
                <source src={src} type="video/mp4" />
              </video>
            ) : (
              <img src={src} alt={caption} />
            )}
          </ItemWrap>
          <RightBox {...{ caption }} />
          {next && <NextBtn {...{ setItem, item: next }} />}
          {prev && <PrevBtn {...{ setItem, item: prev }} />}
        </Content>
      </ContentWrapper>
      <Background onClick={() => setItem(null)}>
        <CloseBtn />
      </Background>
    </Wrapper>
  )
}
