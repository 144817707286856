import React, { useState } from "react"
import styled from "styled-components"
import InstagramItem from "./InstagramItem"
import Modal from "./Modal"
import NavBar from "./NavBar"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(250,250,250,1);
`

const Main = styled.div`
  // margin: 0 auto 30px;
  max-width: 935px;

  display: flex;
  flex-wrap: wrap;
  flex: 1 0 30%;
  justify-content: space-between;

  @media (min-width: 736px) {
    // padding: 30px 20px 0;
    width: calc(100% - 40px);
  }
`

const Instagram = ({ items }) => {
  const [item, setItem] = useState(null)

  return (
    <>
      <NavBar />
      <Wrapper>
        <Main>
          {items.map(props => (
            <InstagramItem key={props.path} {...{ ...props, setItem }} />
          ))}
          {item && <Modal {...{ item, items, setItem }} />}
        </Main>
      </Wrapper>
    </>
  )
}

export default Instagram
