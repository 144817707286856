// import React, { useRef } from "react"
import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const VideoSvg = () => (
  <svg aria-label="Video" fill="#fff" height="18" viewBox="0 0 48 48" width="18">
    <path d="M9.6 46.5c-1 0-2-.3-2.9-.8-1.8-1.1-2.9-2.9-2.9-5.1V7.3c0-2.1 1.1-4 2.9-5.1 1.9-1.1 4.1-1.1 5.9 0l30.1 17.6c1.5.9 2.3 2.4 2.3 4.1 0 1.7-.9 3.2-2.3 4.1L12.6 45.7c-.9.5-2 .8-3 .8z" />
  </svg>
)

const VideoIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const VideoIcon = () => (
  <VideoIconWrapper>
    <VideoSvg />
  </VideoIconWrapper>
)

const ItemWrapper = styled.div`
  width: calc((100% - 56px) / 3);
  margin-bottom: 28px;

  @media (max-width: 735px) {
    width: calc((100% - 6px) / 3);
    margin-bottom: 6px;
  }
`

const ItemBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.075s;
`

const ItemHolder = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;

  &:hover > ${ItemBg} {
    opacity: 1;
  }

  & > video,
  & > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }
`

const InstagramItem = ({
  taken_at: takenAt,
  src,
  gatsbyImageData,
  caption,
  location,
  path,
  isVideo,
  setItem,
}) => {
  // const vidRef = useRef(null)
  // const handlePlayVideo = () => {
  //   vidRef.current.play()
  // }
  return (
    <ItemWrapper>
      <ItemHolder
        onClick={() =>
          setItem({
            takenAt,
            src,
            gatsbyImageData,
            caption,
            location,
            path,
            isVideo,
          })
        }
      >
        {isVideo ? (
          <video controls={false} width="250">
            <source src={src} type="video/mp4" />
          </video>
        ) : (
          // <img src={src} width="250" height="250" alt={caption} />
          <GatsbyImage
            image={gatsbyImageData}
            alt={caption}
          />
        )}

        {isVideo && <VideoIcon />}
        <ItemBg />
      </ItemHolder>
    </ItemWrapper>
  )
}

export default InstagramItem
